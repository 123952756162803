import Vue, { CreateElement } from 'vue'
import { unpackTimeIntegerToString } from 'src/util/datetime'
import { Activity } from 'src/models/activity'
import { StaffAttendanceResponse } from 'src/models/api/staffAttendanceResponse'
import { NotifyOptions } from 'vue-notifyjs'

function doNotify(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  const notifyObj: NotifyOptions = {
    message: msg,
    verticalAlign: opts.verticalAlign || 'top', // top | bottom
    horizontalAlign: opts.horizontalAlign || 'center', // right | center | left
    type: opts.type || 'info', // info | warning | danger | success | primary
    timeout: opts.timeout || 5 * 1000,
  }
  if (opts.title) { notifyObj.title = opts.title }
  if (opts.icon) { notifyObj.icon = opts.icon }
  if (opts.component) { notifyObj.component = opts.component }

  // なぜかvue.$notifyと呼ぶとコンパイルエラー. vue-notifyjs.d.tsで定義はしてるはずだが.
  (vue as any).$notify(notifyObj)
}

export function notifySuccess1(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  opts.timeout = opts.timeout || 3 * 1000
  doNotify(vue, msg, opts)
}

export function notifyError1(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  console.error(msg)
  if (opts.err) {
    console.error(opts.err)
  }
  opts.type = opts.type || 'danger'
  opts.timeout = opts.timeout || 60 * 1000
  opts.icon = opts.icon || 'fas fa-exclamation-triangle'
  doNotify(vue, msg, opts)
}

export function notifyError2(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  opts.verticalAlign = opts.verticalAlign || 'bottom'
  notifyError1(vue, msg, opts)
}

// 個別の通知
export interface NotifyAttendanceUpdateSuccessMsgStructure {
  header: {
    name: string
  }
  detail: StaffAttendanceResponse
}
export function notifyAttendanceUpdateSuccess(
  vue: Vue,
  msgStructure: NotifyAttendanceUpdateSuccessMsgStructure,
  opts: NotifyOptions = {}
) {
  const word1 = msgStructure.detail.work_end_time === null ? '出勤' : '退勤'
  const renderFunc = (createElement: CreateElement) => {
    const headerElem = createElement('div', { class: 'msg-header' },
      [
        createElement('span', { class: 'staff-name' }, msgStructure.header.name),
        createElement('span', `さんの${word1}を登録しました`)
      ])

    const detail = msgStructure.detail
    const content = []
    content.push(createElement('span', { class: 'lbl' }, '勤務'))
    let h, m
    [h, m] = unpackTimeIntegerToString(detail.work_start_time)
    content.push(createElement('span', { class: 'start-time' }, `${h}:${m}`))
    content.push(createElement('span', { class: 'tilda' }, '〜'))
    if (detail.work_end_time !== null) {
      [h, m] = unpackTimeIntegerToString(detail.work_end_time)
      content.push(createElement('span', { class: 'end-time' }, `${h}:${m}`))
    }
    const detailElem = createElement('div', { class: 'msg-line' }, content)

    return createElement('div', [ headerElem, detailElem ])
  }
  opts.timeout = opts.timeout || 3 * 1000
  opts.component = { render: renderFunc }
  doNotify(vue, '', opts)
}

export interface NotifyAttendanceReopenSuccessMsgStructure {
  header: {
    name: string
  },
  detail: StaffAttendanceResponse
}
export function notifyAttendanceReopenSuccess(
  vue: Vue,
  msgStructure: NotifyAttendanceReopenSuccessMsgStructure,
  opts: NotifyOptions = {}
) {
  const renderFunc = (createElement: CreateElement) => {
    const headerElem = createElement('div', { class: 'msg-header' },
      [
        createElement('span', { class: 'staff-name' }, msgStructure.header.name),
        createElement('span', `さんの退勤登録を取り消しました`)
      ])
    return createElement('div', [ headerElem ])
  }
  opts.timeout = opts.timeout || 3 * 1000
  opts.component = { render: renderFunc }
  doNotify(vue, '', opts)
}

export interface NotifyActivityUpdateSuccessMsgStructure {
  header: {
    name: string
  }
  details: Activity[]
}
export function notifyActivityUpdateSuccess(
  vue: Vue,
  msgStructure: NotifyActivityUpdateSuccessMsgStructure,
  opts: NotifyOptions = {}
) {
  const renderFunc = (createElement: CreateElement) => {
    const header = createElement('div', { class: 'msg-header' },
      [
        createElement('span', { class: 'staff-name' }, msgStructure.header.name),
        createElement('span', 'さんの作業を登録しました')
      ])
    const details = msgStructure.details.map(line => {
      const content = []
      // @ts-ignore
      content.push(createElement('span', { class: 'lbl' }, line.activity_master.name))
      let h, m
      [h, m] = unpackTimeIntegerToString(line.start_time)
      content.push(createElement('span', { class: 'start-time' }, `${h}:${m}`))
      content.push(createElement('span', { class: 'tilda' }, '〜'))
      if (line.end_time !== null) {
        [h, m] = unpackTimeIntegerToString(line.end_time)
        content.push(createElement('span', { class: 'end-time' }, `${h}:${m}`))
      }
      return createElement('div', { class: 'msg-line' }, content)
    })
    return createElement('div', [ header, ...details ])
  }
  opts.timeout = opts.timeout || 3 * 1000
  opts.component = { render: renderFunc }
  doNotify(vue, '', opts)
}

export function notifyQuantityUpdateSuccess(
  vue: Vue,
  opts: NotifyOptions = {},
) {
  const renderFunc = (createElement: CreateElement) => {
    const header = createElement('div', { class: 'msg-header' },
      [
        createElement('span', '数量を更新しました')
      ])
    return createElement('div', [ header ])
  }
  opts.timeout = opts.timeout || 3 * 1000
  opts.component = { render: renderFunc }
  doNotify(vue, '', opts)
}
