export function arrayUnique<T, K extends string | number = string | number>(
  array: T[],
  uniqueBy: (item: T) => K
): T[] {
  const map = array.reduce<Record<K, T>>((acc, item) => {
    acc[uniqueBy(item)] = item // 後勝ち
    return acc
  }, {} as Record<K, T>)
  return Object.values(map)
}
