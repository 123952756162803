import { SetupContext } from '@vue/composition-api'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { USER_WORKPLACE_ROLE_W_OWNER, USER_WORKPLACE_ROLE_W_LEADER } from 'src/consts'
import { Vue } from 'vue/types/vue'

export async function ensureSuperAdmin(context: SetupContext): Promise<void> {
  await ensureUserAndMasters(context)
  const root = context.root
  if (!root.$store.getters['user/hasRoleSuperAdmin']) {
    await root.$router.replace({ name: 'Login' })
    throw new Error('Unauthorized')
  }
}

export async function ensureWorkplaceRoleGteOwner(context: SetupContext | Vue, workplaceId: number): Promise<void> {
  const root: Vue = 'root' in context ? context.root : context
  if (!(await userHasWorkplaceRoleGteOwner(context, workplaceId))) {
    await root.$router.replace({ name: 'Login' })
    throw new Error('Unauthorized')
  }
}

export async function ensureWorkplaceRoleGteLeader(context: SetupContext | Vue, workplaceId: number): Promise<void> {
  const root: Vue = 'root' in context ? context.root : context
  if (!(await userHasWorkplaceRoleGteLeader(context, workplaceId))) {
    await root.$router.replace({ name: 'Login' })
    throw new Error('Unauthorized')
  }
}

export async function userHasWorkplaceRoleGteOwner(context: SetupContext | Vue, workplaceId: number): Promise<boolean> {
  const root: Vue = 'root' in context ? context.root : context
  await ensureUserAndMasters(context)
  return userHasWorkplaceRoleGteOwnerSync(root, workplaceId)
}

export async function userHasWorkplaceRoleGteLeader(context: SetupContext | Vue, workplaceId: number): Promise<boolean> {
  const root: Vue = 'root' in context ? context.root : context
  await ensureUserAndMasters(context)
  return userHasWorkplaceRoleGteLeaderSync(root, workplaceId)
}

export function userHasWorkplaceRoleGteOwnerSync(vue: Vue, workplaceId: number): boolean {
  const validRoles = [USER_WORKPLACE_ROLE_W_OWNER]
  return _userHasWorkplaceRoleSync(vue, workplaceId, validRoles)
}

export function userHasWorkplaceRoleGteLeaderSync(vue: Vue, workplaceId: number): boolean {
  const validRoles = [USER_WORKPLACE_ROLE_W_OWNER, USER_WORKPLACE_ROLE_W_LEADER]
  return _userHasWorkplaceRoleSync(vue, workplaceId, validRoles)
}

function _userHasWorkplaceRoleSync(vue: Vue, workplaceId: number, roles: string[]) {
  const workplaceRoleMap = vue.$store.getters['user/workplaceRoleMap']
  if (!workplaceRoleMap[workplaceId]) {
    return false
  }
  return roles.includes(workplaceRoleMap[workplaceId].role)
}
