




















































































import { defineComponent, SetupContext, reactive, computed, ref } from '@vue/composition-api'
import { FgInput } from 'src/components/UIComponents'
import userApi from 'src/apis/user'
import Vue from 'vue'
import { login } from 'src/hooks/loginHook'
import browserDetect from 'vue-browser-detect-plugin'

interface State {
  viewMode: string
  isForcePasswordResetMode: boolean
  isLoginMode: boolean
  isPasswordResetMode: boolean
  form: {
    email: string
    password: string
  }
  pwReset: {
    email: string
    emailConfirm: string
  }
  showLoginErrMsg1: boolean
  showPwResetErrMsg1: boolean
  showPwResetErrMsg2: boolean
  isPasswordResetEmailSent: boolean
  showBrowserErrMsg1: boolean
}

export default defineComponent({
  name: 'login',
  setup(_props, context: SetupContext) {
    const root = context.root as Vue
    const state: State = reactive({
      viewMode: 'login',
      isForcePasswordResetMode: computed(() => root.$router.currentRoute.name === 'PasswordResetForm'),
      isLoginMode: computed(() => {
        return state.viewMode === 'login' && !state.isForcePasswordResetMode
      }),
      isPasswordResetMode: computed(() => {
        return state.viewMode === 'pwReset' || state.isForcePasswordResetMode
      }),
      form: {
        email: '',
        password: ''
      },
      showLoginErrMsg1: false,
      pwReset: {
        email: '',
        emailConfirm: '',
      },
      showPwResetErrMsg1: false,
      showPwResetErrMsg2: false,
      showBrowserErrMsg1: false,
      isPasswordResetEmailSent: false,
    })
    // コンポーネントの実態はコンストラクタである.
    // なので、typeofでコンストラクタの型を得てからInstanceTypeで所望の型を得るというイディオム.
    const refTextInputEmail = ref<InstanceType<typeof FgInput>>()
    const refTextInputPassword = ref<InstanceType<typeof FgInput>>()

    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    function ensureLoginFormValuesSynced(): void {
      state.form.email = refTextInputEmail.value.value
      state.form.password = refTextInputPassword.value.value
    }

    async function tryLogin(): Promise<void> {
      state.showLoginErrMsg1 = false
      ensureLoginFormValuesSynced()

      await login(
        root,
        state.form,
        () => {
          location.href = '/'
        },
        () => {
          state.showLoginErrMsg1 = true
        }
      )
    }

    async function tryPasswordReset(): Promise<void> {
      if (!state.pwReset.email) { return }
      state.showPwResetErrMsg1 = false
      state.showPwResetErrMsg2 = false
      if (state.pwReset.email !== state.pwReset.emailConfirm) {
        state.showPwResetErrMsg1 = true
        return
      }
      try {
        await userApi.sendResetPasswordEmail({
          email: state.pwReset.email,
          issuer: this.$route.query.issuer,
        })
        state.isPasswordResetEmailSent = true
      } catch (err) {
        state.showPwResetErrMsg2 = true
      }
    }

    function showLoginMode(): void {
      state.showLoginErrMsg1 = false
      state.viewMode = 'login'
    }

    function showPwResetMode(): void {
      state.showPwResetErrMsg1 = false
      state.showPwResetErrMsg2 = false
      state.isPasswordResetEmailSent = false
      state.viewMode = 'pwReset'
    }
    function checkBrowser(): void {
      if (root.$browserDetect.isIE) {
        state.showBrowserErrMsg1 = true
      }
    }

    return {
      state,
      refTextInputEmail,
      refTextInputPassword,
      tryLogin,
      tryPasswordReset,
      showLoginMode,
      showPwResetMode,
      checkBrowser,
    }
  },
  mounted() {
    Vue.use(browserDetect)
    this.checkBrowser()
  },
})
