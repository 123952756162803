// new VueでEventBusを作っていたののdrop-in replacement.
import origMitt, { Emitter } from 'mitt'

// on => $on などとnew Vueの該当するプロパティ名にコピる
export interface MyEmitter extends Emitter {
  $on: Emitter['on'],
  $off: Emitter['off']
  $emit: Emitter['emit']
}

export default function(): MyEmitter {
  const emitter = origMitt()
  return Object.assign({
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit,
  }, emitter)
}
