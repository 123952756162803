import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  Company,
  companiesResponseToCompanies
} from 'src/models/company'
import { CompaniesResponse } from 'src/models/api/companyResponse'

const apis = {
  index(): Promise<AxiosResponse<CompaniesResponse>> {
    return axios.get(getApiUrl(`/companies`))
  },
}

export const apisWithTransformedData = {
  index(): Promise<Company[]> {
    return apis.index().then(({ data }) => companiesResponseToCompanies(data))
  },
}

export default apis
