import Vue from 'vue'

export interface SidebarLink {
  name: string
  nameMini: string
  icon: string
  path: string
  activePath: string
  alwaysLinkToDefault?: boolean
  requireWorkplaceRoleGteLeader?: boolean
  requireWorkplaceIsWmsCsv?: boolean
}
export type UnresolvedSidebarLink = Omit<SidebarLink, 'path' | 'activePath'> & {
  path?: string
  pathCandMap: {
    default: string
    [index: string]: string
  },
}

export interface SidebarStore {
  showSidebar: boolean
  isMinimized: boolean
  sidebarLinks: SidebarLink[]
  displaySidebar: (value: boolean) => void
  toggleMinimize: () => void
}

export function createSidebarStore(): SidebarStore {
  return {
    showSidebar: false,
    sidebarLinks: [],
    isMinimized: false,
    displaySidebar(value: boolean) {
      this.showSidebar = value
      const bus = Vue.prototype.$sidebarEventBus
      bus.$emit('updateShowSidebar', { showSidebar: this.showSidebar })
    },
    toggleMinimize() {
      document.body.classList.toggle('sidebar-mini')
      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(() => {
        window.dispatchEvent(new Event('resize'))
      }, 180)

      // we stop the simulation of Window Resize after the animations are completed
      window.setTimeout(() => {
        clearInterval(simulateWindowResize)
      }, 1000)

      this.isMinimized = !this.isMinimized
    }
  }
}
