<template src="./template.html"></template>

<script>
import { mapState } from 'vuex'
import notificationMixin from 'src/mixins/notificationMixin'
import userApi from 'src/apis/user'
import companyApi from 'src/apis/company'
import timingControlMixin from 'src/mixins/timingControlMixin'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { useLogiAuth } from 'src/util/logibase'

export default {
  data() {
    return {
      validations: this.getValidationMap(),
      userToEdit: {},
      companyCandidates: [],
      showEditUserModal: false,
      showEditPasswordModal: false,
      showEditOtpRequiredForLoginModal: false,
      useLogiAuth: useLogiAuth(),
    }
  },
  computed: {
    ...mapState(['user']),
    hasError() {
      return this.errors.any()
    },
    hasPasswordConfirmationError() {
      return !!this.errors.firstByRule('password.passwordConfirmation', 'confirmed')
    },
  },
  mixins: [notificationMixin, timingControlMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = {
        required: true,
        max: 255,
      }
      return {
        familyName: ruleStr,
        firstName: ruleStr,
        jobTitle: ruleStr,
        password: {
          required: true,
          min: 8,
        },
        passwordConfirmation: {
          required: true,
          confirmed: 'password',
        },
      }
    },
    clearErrors() {
      this.$validator.reset()
    },
    openEditUserModal() {
      this.userToEdit = Object.assign({}, this.user)
      this.showEditUserModal = true
    },
    openEditPasswordModal() {
      this.userToEdit = Object.assign({}, this.user)
      this.userToEdit.password = ''
      this.userToEdit.password_confirmation = ''
      this.showEditPasswordModal = true
    },
    openEditOtpRequiredForLoginModal() {
      this.userToEdit = Object.assign({}, this.user)
      this.showEditOtpRequiredForLoginModal = true
    },
    async updateUser() {
      const isValid = await this.$validator.validateAll('user')
      if (!isValid) { return }
      try {
        const { data } = await userApi.update(this.userToEdit)
        this.$store.dispatch('user/setMe', data)
        this.closeEditUserModal()
        this.notifySuccess1('マイアカウントを編集しました')
      } catch (err) {
        const msg = 'マイアカウントの編集に失敗しました。管理者に連絡してください。' +
          `(ERR: Settings/User ERR00001, user_id:${this.user.id})`
        this.notifyError1(msg, { err })
      }
    },
    async updatePassword() {
      const isValid = await this.$validator.validateAll('password')
      if (!isValid) { return }
      try {
        await userApi.update(this.userToEdit)
        this.closeEditPasswordModal()
        this.notifySuccess1('パスワードを変更しました')
      } catch (err) {
        const msg = 'パスワードの変更に失敗しました。管理者に連絡してください。' +
          `(ERR: Settings/User ERR00002, user_id:${this.user.id})`
        this.notifyError1(msg, { err })
      }
    },
    async updateEditOtpRequiredForLogin() {
      try {
        let updatedToOtpRequired = false
        if (this.user.otp_required_for_login === false && this.userToEdit.otp_required_for_login === true) {
          updatedToOtpRequired = true
        }
        const { data } = await userApi.update(this.userToEdit)
        this.$store.dispatch('user/setMe', data)
        this.closeEditOtpRequiredForLoginModal()
        this.notifySuccess1('2要素認証の設定を変更しました')
        // 2要素認証 off => onにupdateされ、かつLogiAuth利用時の場合は強制ログアウトさせる
        if (updatedToOtpRequired && this.useLogiAuth) {
          location.href = '/users/saml/idp_logout'
        }
      } catch (err) {
        const msg = '2要素認証設定の変更に失敗しました。管理者に連絡してください。' +
          `(ERR: Settings/User ERR00002, user_id:${this.user.id})`
        this.notifyError1(msg, { err })
      }
    },
    async getCompanies() {
      if (this.user.has_role_super_admin) {
        const { data: companies } = await companyApi.index()
        this.companyCandidates = companies
      }
    },
    closeEditUserModal() {
      this.userToEdit = {}
      this.clearErrors()
      this.showEditUserModal = false
    },
    closeEditPasswordModal() {
      this.userToEdit = {}
      this.clearErrors()
      this.showEditPasswordModal = false
    },
    closeEditOtpRequiredForLoginModal() {
      this.userToEdit = {}
      this.clearErrors()
      this.showEditOtpRequiredForLoginModal = false
    },
    makeFuncGated() {
      this.updateUser = this.makeAsyncFuncGated(this.updateUser)
      this.updatePassword = this.makeAsyncFuncGated(this.updatePassword)
    },
    async onCompanyChange(company) {
      this.userToEdit.company_id = company.id
    }
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, 'マイアカウント')
    this.getCompanies()
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
