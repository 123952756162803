import axios from 'axios'
import * as utils from 'src/apis/apiUtils'

export default {
  index({ workplaceId, params }) {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs`), {
      params: params || {}
    })
  },
  create({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs`), data)
  },
  update({ workplaceId, data }) {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs/${data.id}`), data)
  },
  delete({ workplaceId, itemId }) {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs/${itemId}`))
  },
  importCsv({ workplaceId, data, headers }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs/import_csv`),
      data,
      { headers }
    )
  },
  bulkUpdate({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs/bulk_update`), data)
  },
}
