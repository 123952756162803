<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import workplaceApi from 'src/apis/workplace'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { userHasWorkplaceRoleGteOwner, userHasWorkplaceRoleGteLeader } from 'src/hooks/appRoleHook'

export default {
  data() {
    return {
      workplace: null,
      isReady: false,
      isGteLeader: false,
      isGteOwner: false,
    }
  },
  computed: {
    workplaceId() {
      return this.$route.params.workplaceId
    },
    ...mapGetters('workplace', {
      useStaffAgency: 'useStaffAgency',
      useStaffLabel: 'useStaffLabel',
      useActivityGroup: 'useActivityGroup',
    }),
  },
  async mounted() {
    setPageName(this, 'サービス横断設定')
    const { data } = await workplaceApi.show(this.workplaceId)
    this.workplace = data
    this.isGteLeader = await userHasWorkplaceRoleGteLeader(this, this.workplaceId)
    this.isGteOwner = await userHasWorkplaceRoleGteOwner(this, this.workplaceId)
    this.isReady = true
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
