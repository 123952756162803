export default {
  methods: {
    isGuestPage(routeName) {
      routeName = routeName || this.$route.name
      const arr = [ 'Login', 'PasswordReset' ]
      return arr.indexOf(routeName) !== -1
    },
    getTopbarModeFromRouteName(routeName) {
      let mode = 'mode1'
      if (routeName.startsWith('Workplace')) {
        if (
          routeName === 'WorkplaceSelect' ||
          routeName === 'WorkplaceFeatureMap' ||
          routeName === 'WorkplaceCsvs'
        ) {
          // 日付なし
          mode = 'mode1'
        } else {
          // 日付selectを表示
          mode = 'mode2'
        }
      } else if (routeName.startsWith('Results')) {
        if (routeName === 'ResultsSelect') {
          // 日付なし
          mode = 'mode1'
        } else {
          // 日付range selectを表示
          mode = 'mode3'
        }
      } else if (routeName.startsWith('Analytics')) {
        if (routeName === 'AnalyticsCSV' || routeName === 'AnalyticsSelect') {
          // 日付なし
          mode = 'mode1'
        } else if (routeName === 'AnalyticsWorkhours2') {
          // 日付select
          mode = 'mode2'
        } else {
          // 日付range selectを表示
          mode = 'mode3'
        }
      }
      return mode
    },
    routerShowNextPage(evt, routeObj) {
      if (evt.ctrlKey || evt.metaKey) {
        const obj = this.$router.resolve(routeObj)
        window.open(obj.href, '_blank')
      } else {
        const currentRoute = this.$router.currentRoute
        if (currentRoute.name === routeObj.name) {
          // prevent 'Navigation Duplicated' error.
          const nextRoute = this.$router.resolve(routeObj).route
          const areAllParamsSame = Object.entries(currentRoute.params).every(([k, v]) => {
            return v === nextRoute.params[k]
          })
          const areAllQueriesSame = Object.entries(currentRoute.query).every(([k, v]) => {
            return v === nextRoute.query[k]
          })
          if (areAllParamsSame && areAllQueriesSame) { return }
        }
        this.$router.push(routeObj)
      }
    },
  }
}
