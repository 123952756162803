<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import notificationMixin from 'src/mixins/notificationMixin'
import masterMixin from 'src/mixins/masterMixin'
import budgetGroupApi from 'src/apis/workplace_masters/budget_group'
import budgetUnitApi from 'src/apis/workplace_masters/budget_unit'
import timingControlMixin from 'src/mixins/timingControlMixin'
import windowMixin from 'src/mixins/windowMixin'
import ListSwitchPanel from '../ListSwitchPanel'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureWorkplaceRoleGteOwner } from 'src/hooks/appRoleHook'

const api = budgetUnitApi
const displayPageName = '荷主'
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' }

export default {
  components: { ListSwitchPanel },
  data() {
    return {
      displayPageName: displayPageName,
      isSuperAdmin: false,
      searchParams: {
        displayListDisabledItem: false,
      },
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,
      budgetGroupCandidates: [],
      enabledBudgetGroups: [],

      validations: this.getValidationMap(),

      showCsvImportResultModal: false,
      csvImportResult: {
        successCount: 0,
        failCount: 0,
        failReasons: [],
      },
      isLoadCsv: false,
      fixTable: true,
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    ...mapGetters('workplace', {
      useBudgetGroup: 'useBudgetGroup',
      useCreateAndUpdateInfo: 'useCreateAndUpdateInfo',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    hasList() {
      return this.list.length > 0
    },
    hasError() {
      return this.errors.any()
    },
    rowWidth() {
      if (!this.useBudgetGroup && !this.useCreateAndUpdateInfo) {
        return 800
      }
      return 655 +
        Number(this.useBudgetGroup) * 290 +
        Number(this.useCreateAndUpdateInfo) * 740
    }
  },
  mixins: [ notificationMixin, masterMixin, timingControlMixin, windowMixin ],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = { required: true, max: 255 }
      const rulePercentage = {
        required: true,
        decimal: 2,
        min_value: 0,
        max_value: 100,
      }
      return {
        name: ruleStr,
        target_profit_rate_percent: rulePercentage,
      }
    },
    getApiParams() {
      let apiParams = {}
      if (!this.searchParams.displayListDisabledItem) {
        apiParams['is_enabled'] = true
      }
      return apiParams
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getList() {
      const apiMethod = this.isSuperAdmin ? 'index' : 'shared'
      const p1 = api[apiMethod]({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      })
      const p2 = budgetGroupApi.index({ workplaceId: this.workplaceId, params: { is_enabled: true } })
      const [
        { data: budgetUnits },
        { data: budgetGroups },
      ] = await Promise.all([p1, p2])
      budgetUnits.forEach(e => {
        // *_rate
        e.target_profit_rate_percent = (e.target_profit_rate * 100).toFixed(2)
      })
      this.list = budgetUnits
      this.enabledBudgetGroups = budgetGroups
      this.enabledBudgetGroups.unshift({ id: null })
    },
    async getListWithUpdatedSearchParams(params) {
      this.searchParams.displayListDisabledItem = params.displayDisabledModel
      await this.getList()
    },
    openSaveModal(item) {
      // initialize budgetGroupCandidates
      this.budgetGroupCandidates = this.enabledBudgetGroups
      let errItems = []
      // budget_group_id can be null
      /* if (this.budgetGroupCandidates.length === 0) {
       *   errItems.push('所属管理グループ')
       * } */
      if (errItems.length > 0) {
        errItems.forEach(item => {
          const msg = `先に${item}を作成してください。`
          this.notifyError1(msg)
        })
        return
      }

      let saveCandidate = {}
      if (!item.id) {
        saveCandidate.isNew = true
        saveCandidate.is_enabled = true
      } else {
        saveCandidate = JSON.parse(JSON.stringify(item))
        // check whether saved budget_group is in budgetGroupCandidates or not.
        // If not then add it.
        const hasBudgetGroupInCandidates =
        this.budgetGroupCandidates.find(e => e.id === saveCandidate.budget_group_id)
        if (!hasBudgetGroupInCandidates) {
          this.budgetGroupCandidates = [item.budget_group].concat(this.budgetGroupCandidates)
        }
      }
      this.saveCandidate = saveCandidate
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      this.clearErrors()
      this.showSaveModal = false
    },
    async saveItem() {
      const isValid = await this.$validator.validate()
      if (!isValid) { return }

      const opType = this.saveCandidate.isNew ? 'create' : 'update'
      // *_rate
      this.saveCandidate.target_profit_rate =
        (parseFloat(this.saveCandidate.target_profit_rate_percent) / 100).toFixed(4)

      if (!this.useBudgetGroup && !this.saveCandidate.budget_group_id) {
        this.saveCandidate.budget_group_id = this.budgetGroupCandidates.filter(e => { return e.id })[0].id
      }
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await api[opType](reqObj)
        this.getList()
        this.closeSaveModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = `その荷主名は既に使用されています。`
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.deleteCandidate = {}
      this.showDeleteModal = false
    },
    async deleteItem() {
      const opType = 'delete'
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteCandidate.id,
        }
        await api[opType](reqObj)
        this.getList()
        this.closeDeleteModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'is_undeletable') {
          const msg = '一定時間が経過したため削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00003'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem)
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem)
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder)
    },
    openFileDialog() {
      this.$refs.csvInput.$el.children[0].click()
    },
    async onCsvUpload(e) {
      // ファイルが選択されていない場合は何もしない
      const selectedFileList = e.target.files
      if (!selectedFileList || !selectedFileList[0]) { return }

      this.isLoadCsv = true
      try {
        const data = new FormData()
        data.append('csv', selectedFileList[0])
        const reqObj = {
          workplaceId: this.workplaceId,
          data: data,
          headers: { 'content-type': 'multipart/form-data' },
        }
        const result = (await api.importCsv(reqObj)).data
        this.getList()
        this.openCsvImportResultModal(result)
      } catch (err) {
        this.isLoadCsv = false
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        let msg = ''
        switch (true) {
          case errStatus === 400 && errRes.reason === 'invalid_data':
            msg = 'ファイル形式、またはCSVフォーマットが間違っています。'
            break
          case errStatus === 400 && errRes.reason === 'import_limit_flag':
            msg = '取込み行数は最大100行です。'
            break
          case errStatus === 400 && errRes.reason === 'invalid_contents':
            msg = 'ファイル内容が不正です。'
            break
        }
        if (msg) this.notifyError1(msg, { timeout: 5 * 1000 })
      }
    },
    openCsvImportResultModal(result) {
      this.isLoadCsv = false
      this.csvImportResult.successCount = result.success_count
      this.csvImportResult.failCount = result.fail_count
      this.csvImportResult.failReasons = result.fail_reasons
      this.showCsvImportResultModal = true
    },
    closeCsvImportResultModal() {
      this.showCsvImportResultModal = false
    },
    isFailedImport() {
      return this.csvImportResult.failReasons.length > 0
    },
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, displayPageName)
    await this.ensureUserAndMasters()
    if (this.$store.getters['user/hasRoleSuperAdmin']) {
      this.isSuperAdmin = true
    }
    await ensureWorkplaceRoleGteOwner(this, this.workplaceId)
    await this.getList()
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
