import axios from 'axios'
import { Vue } from 'vue/types/vue'

export interface LoginParams {
  email: string
  password: string
}

export async function login(vue: Vue, params: LoginParams, onSuccess: () => void, onError: (e: Error) => void) {
  // loginしようとするときは明示的に消す.
  // expiredなトークンを送り続けるとログインできないので.
  delete axios.defaults.headers['Authorization']

  try {
    const res = await vue.$store.dispatch('user/login', params)
    await vue.$store.dispatch('localStorage/set', {
      key: 'serverToken',
      val: res['access_token'],
    })
    onSuccess()
  } catch (e: any) {
    onError(e)
  }
}
