import axios from 'axios'
import * as utils from 'src/apis/apiUtils'

export default {
  index({ workplaceId }) {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces`))
  },
  create({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces`), data)
  },
  createWithUser({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces/create_with_user`), data)
  },
  update({ workplaceId, data }) {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces/${data.id}`), data)
  },
  delete({ workplaceId, itemId }) {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces/${itemId}`))
  },
  getCompanies({ workplaceId }) {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces/get_companies`))
  },
}
