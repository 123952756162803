import { SidebarLink, UnresolvedSidebarLink } from 'src/components/UIComponents/Sidebar/createSidebarStore'

export const resolvedSidebarLinksMap: Record<string, SidebarLink[]> = {
  top: [
    {
      name: 'センター',
      nameMini: 'センター',
      icon: 'fal fa-warehouse-alt',
      path: '/workplace/select',
      activePath: '/workplace/select',
    },
    {
      name: '共通設定',
      nameMini: '共通設定',
      icon: 'fal fa-cog',
      path: '/settings',
      activePath: '/settings',
    },
  ],
}

export const unresolvedSidebarLinksMap: Record<string, UnresolvedSidebarLink[]> = {
  workplace: [
    {
      name: '設定',
      nameMini: '設定',
      icon: 'fal fa-cog',
      pathCandMap: {
        default: '/workplace/:workplaceId/settings',
      },
    },
  ],
}
