import DashboardLayout from 'src/views/Dashboard/Layout/DashboardLayout.vue'

// 注) これを変えたらviews/Dashboard/Layout/sidebarLinkGroupsも確認すること
const routes = [
  {
    path: '',
    component: DashboardLayout,
    redirect: 'workplace/select',
  },
  {
    path: 'workplace',
    component: DashboardLayout,
    redirect: 'workplace/select',
    children: [
      {
        path: 'select',
        name: 'WorkplaceSelect',
        component: require('src/views/Dashboard/Workplace/Select').default,
      },
      {
        path: ':workplaceId/settings',
        name: 'SettingsSelect',
        component: require('src/views/Dashboard/Workplace/Settings/Select').default,
      },
      {
        path: ':workplaceId/settings/workplace',
        name: 'SettingsWorkplace',
        component: require('src/views/Dashboard/Workplace/Settings/Workplace').default,
      },
      {
        path: ':workplaceId/settings/budget-group',
        name: 'SettingsBudgetGroup',
        component: require('src/views/Dashboard/Workplace/Settings/BudgetGroup').default,
      },
      {
        path: ':workplaceId/settings/budget-unit',
        name: 'SettingsBudgetUnit',
        component: require('src/views/Dashboard/Workplace/Settings/BudgetUnit').default,
      },
      {
        path: ':workplaceId/settings/staff-agency',
        name: 'SettingsStaffAgency',
        component: require('src/views/Dashboard/Workplace/Settings/StaffAgency').default,
      },
      {
        path: ':workplaceId/settings/staff-label',
        name: 'SettingsStaffLabel',
        component: require('src/views/Dashboard/Workplace/Settings/StaffLabel').default,
      },
      {
        path: ':workplaceId/settings/staff',
        name: 'SettingsStaff',
        component: require('src/views/Dashboard/Workplace/Settings/Staff').default,
      },
      {
        path: ':workplaceId/settings/share-settings',
        name: 'SettingsShareSettings',
        component: require('src/views/Dashboard/Workplace/Settings/ShareSettings').default,
      },
    ]
  },
  {
    path: 'settings',
    component: DashboardLayout,
    redirect: 'settings/select',
    children: [
      {
        path: 'select',
        name: 'GeneralSettingsSelect',
        component: require('src/views/Dashboard/Settings/Select').default,
      },
      {
        path: 'user',
        name: 'GeneralSettingsUser',
        component: require('src/views/Dashboard/Settings/User').default,
      },
    ]
  },
]

export default routes
