







import * as C from 'src/consts'
import { defineComponent, reactive, SetupContext } from '@vue/composition-api'

interface State {
  maxBulkUpdatableCount: number
}

function setupState(context: SetupContext): State {
  return reactive({
    maxBulkUpdatableCount: C.DATA_MAX_BULK_UPDATE_ITEM_COUNT,
  })
}

export default defineComponent({
  setup(props, context: SetupContext) {
    const state = setupState(context)

    return {
      state
    }
  }
})
