import masterApi from '@/apis/master'

export default {
  methods: {
    loadUserAndMasters() {
      const userPromise = this.loadUser_()
      this.loadMasters_(userPromise)
    },
    loadUser_() {
      return this.$store.dispatch('user/getMe')
    },
    loadMasters_(userPromise) {
      const prms = masterApi.getAll()
        .then(({ data }) => {
          const now = new Date()
          Object.values(data.lovs).forEach(obj => {
            obj.map = obj.vals.reduce((acc, e) => {
              acc[e.key] = e; return acc
            }, {})
            // 期限内のもののみ抽出したもの
            obj.vals_inuse = obj.vals.filter(e => {
              return new Date(e.start_use) <= now &&
                now < new Date(e.end_use)
            })
            obj.map_inuse = obj.vals_inuse.reduce((acc, e) => {
              acc[e.key] = e; return acc
            }, {})
          })

          const prms = window.master.$promise
          window.master = data
          window.master.$promise = prms
          // wait for user info
          return userPromise
        })
        .then(() => {
          return window.master
        })
      window.master = { $promise: prms, lovs: {} }
    },
    async waitForMasters(arg) {
      await window.master.$promise
      return arg
    },
    async ensureUserAndMasters() {
      const promises = [
        this.$store.dispatch('user/getMe'),
        window.master.$promise,
      ]
      const [user, masters] = await Promise.all(promises)
      return { user, masters }
    },
    // タブレット用.
    async mobileEnsureUserAndMasters() {
      const promises = [
        this.$store.dispatch('user/getMe'),
        window.master.$promise,
      ]
      if (
        this.$route.params.workplaceId &&
        !this.$store.getters['workplace/isWorkplaceSet']
      ) {
        const prms = this.$store.dispatch(
          'workplace/fetchWorkplaceById',
          { workplaceId: this.$route.params.workplaceId },
        )
        promises.push(prms)
      }
      const [user, masters] = await Promise.all(promises)

      // tablet patternが必要なケースはこの時点でworkplaceが取れているはずなので、
      // 必要ならセット
      if (
        this.$route.params.tabletPatternId &&
        !this.$store.getters['tabletPattern/isTabletPatternSet']
      ) {
        const patterns = this.$store.getters['workplace/tabletPatterns']
        for (const pattern of patterns) {
          if (pattern.id === parseInt(this.$route.params.tabletPatternId)) {
            this.$store.dispatch('tabletPattern/setTabletPattern', pattern)
            break
          }
        }
      }

      return { user, masters }
    },
  },
}
