export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/samples/admin/overview'
  },
  {
    name: 'Pages',
    icon: 'nc-icon nc-book-bookmark',
    children: [
      {
        name: 'Timeline',
        path: '/samples/pages/timeline'
      },
      {
        name: 'User Profile',
        path: '/samples/pages/user'
      },
      {
        name: 'Login',
        path: '/samples/login'
      },
      {
        name: 'Register',
        path: '/samples/register'
      },
      {
        name: 'Lock',
        path: '/samples/lock'
      }
    ]
  },
  {
    name: 'Components',
    icon: 'nc-icon nc-layout-11',
    children: [{
      name: 'Buttons',
      path: '/samples/components/buttons'
    },
    {
      name: 'Grid System',
      path: '/samples/components/grid-system'
    },
    {
      name: 'Panels',
      path: '/samples/components/panels'
    },
    {
      name: 'Sweet Alert',
      path: '/samples/components/sweet-alert'
    },
    {
      name: 'Notifications',
      path: '/samples/components/notifications'
    },
    {
      name: 'Icons',
      path: '/samples/components/icons'
    },
    {
      name: 'Typography',
      path: '/samples/components/typography'
    }]
  },
  {
    name: 'Forms',
    icon: 'nc-icon nc-ruler-pencil',
    children: [{
      name: 'Regular Forms',
      path: '/samples/forms/regular'
    },
    {
      name: 'Extended Forms',
      path: '/samples/forms/extended'
    },
    {
      name: 'Validation Forms',
      path: '/samples/forms/validation'
    },
    {
      name: 'Wizard',
      path: '/samples/forms/wizard'
    }
    ]
  },
  {
    name: 'Table List',
    icon: 'nc-icon nc-single-copy-04',
    collapsed: true,
    children: [{
      name: 'Regular Tables',
      path: '/samples/table-list/regular'
    },
    {
      name: 'Extended Tables',
      path: '/samples/table-list/extended'
    },
    {
      name: 'Paginated Tables',
      path: '/samples/table-list/paginated'
    }
    ]
  },
  {
    name: 'Maps',
    icon: 'nc-icon nc-pin-3',
    children: [{
      name: 'Google Maps',
      path: '/samples/maps/google'
    },
    {
      name: 'Full Screen Maps',
      path: '/samples/maps/full-screen'
    },
    {
      name: 'Vector Maps',
      path: '/samples/maps/vector-map'
    }
    ]
  },
  {
    name: 'Widgets',
    icon: 'nc-icon nc-box',
    path: '/samples/admin/widgets'
  },
  {
    name: 'Charts',
    icon: 'nc-icon nc-chart-bar-32',
    path: '/samples/charts'
  },
  {
    name: 'Calendar',
    icon: 'nc-icon nc-calendar-60',
    path: '/samples/calendar'
  },
]
