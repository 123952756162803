import { Route } from 'vue-router/types/index'

const sps = [
  'SP_LC',
  'SP_LM',
  'SP_LB',
  'SP_LS',
]

const redirectableRegExPaths = [
  /^\/workplace\/.*/,
  /^\/print\/.*/,
  /^\/settings.*/,
]

export function useLogiAuth(): boolean {
  return process.env.VUE_APP_USE_LOGI_AUTH === 'true'
}

export function ensureNavigatablePath(to: Route): string {
  /* Ensure the redirection target. */
  // to.query.sp must be defined in sps.
  if (typeof to.query.sp !== 'string' || !sps.includes(to.query.sp)) { return '/' }
  // to.query.link must be defined in redirectableRegExPaths
  if (typeof to.query.link !== 'string') { return '/' }
  const link = to.query.link
  const filtered = redirectableRegExPaths.filter((path) => link.match(path))
  if (filtered.length === 0) { return '/' }
  localStorage.setItem('sp', to.query.sp)
  localStorage.setItem('splink_target', to.query.link)
  return to.query.link
}
