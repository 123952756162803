import dashboardRoutes from './dashboard'
import sampleDashboardRoutes from './sample_dashboard'
import { Route, NavigationGuardNext } from 'vue-router/types/index'
import { ensureNavigatablePath, useLogiAuth } from 'src/util/logibase'

let routes = [
  {
    path: '/',
    component: require('src/views/Dashboard.vue').default,
    children: dashboardRoutes,
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      if (to.name === 'Login') {
        location.href = '/users/saml/sign_in'
      }
    },
  },
  {
    path: '/password-reset-form',
    name: 'PasswordResetForm',
    component: require('src/views/Login.vue').default
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: require('src/views/PasswordReset.vue').default
  },
  {
    path: '/samples',
    // lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "samples" */ 'src/views/Samples/SampleDashboard.vue'),
    children: sampleDashboardRoutes,
  },
  {
    path: '/qrcode-reader-sample',
    component: () => import(/* webpackChunkName: "samples" */ 'src/views/Samples/SampleQrcodeReader.vue'),
    name: 'SampleQrcodeReader',
  },
  // route used to redirect to LogiCoredata's page from other SPs.
  // e.g. https://logi-core.kurando.io/trans?link=/settings&sp=SP_LM
  {
    path: '/trans',
    beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext) => {
      // target page ("link" query param ) will be checked within ensureNavigatablePath function
      next({ path: ensureNavigatablePath(to) })
    },
  },
  // catch all
  {
    path: '*',
    redirect: { name: 'Login' },
  }
]

if (process.env.NODE_ENV === 'development') {
  // Only for NODE_ENV = development
  if (useLogiAuth()) {
    // Even LogiAuth is turned on,
    // developer can directly login to app from  '/manual-login'
    routes.splice(2, 0, {
      path: '/manual-login',
      name: 'ManualLogin',
      component: require('src/views/Login.vue').default
    })
  } else {
    // When LogiAuth is turned off,
    // '/login' will be replaced to an ordinary login page, where user can
    // directly login to app.
    routes = routes.filter((e) => {
      return e.name !== 'Login'
    })
    routes.splice(1, 0, {
      path: '/login',
      name: 'Login',
      component: require('src/views/Login.vue').default
    })
  }
}

export default routes
