








import { defineComponent, SetupContext, computed, PropType, inject } from '@vue/composition-api'
import {
  GET_CURRENT_SORT_SPECS_KEY,
  ON_SORT_FUNC_KEY,
  SortSpec
} from 'src/components/Workplace/Sorter2Container.vue'
import { compareStaff } from 'src/hooks/staffSortHook'

export type CompareFuncType = 'default' | 'staffName'

const SORT_STATE_NONE = 0
const SORT_STATE_ASC = 1
const SORT_STATE_DESC = 2

// スタッフ名のソート用
export function compareFuncStaff(a: any, b: any): number {
  return compareStaff(a, b, true)
}

export default defineComponent({
  name: 'Sorter2',
  props: {
    label: {
      type: String,
      default: '',
    },
    sortKey: {
      type: String,
      default: 'id',
    },
    compareFuncType: {
      type: String as PropType<CompareFuncType>,
      default: 'default',
    }
  },
  setup(props, context: SetupContext) {
    const getCurrentSortSpecs = inject(GET_CURRENT_SORT_SPECS_KEY)
    const onSort = inject(ON_SORT_FUNC_KEY)
    if (!getCurrentSortSpecs || !onSort) {
      throw new Error('Sorter2 not used in the context of Sorter2Container')
    }
    const currentSortSpecs = getCurrentSortSpecs()

    const currentSortState = computed(() => {
      // currentSortSpecsが複数あっても、画面上ソート中として表示するのは最優先のソート列のみとする
      // (将来的には、複数列同時にソートしている様を見せるようなこともあるかもしれない)
      const matchedSortSpec = currentSortSpecs.value.slice(0, 1).find(e => e.key === props.sortKey)
      if (!matchedSortSpec) { return SORT_STATE_NONE }
      return matchedSortSpec.asc ? SORT_STATE_ASC : SORT_STATE_DESC
    })
    const sortIconClass = computed(() => {
      switch (currentSortState.value) {
        case SORT_STATE_NONE:
          return 'fa-sort'
        case SORT_STATE_ASC:
          return 'fa-sort-up'
        case SORT_STATE_DESC:
          return 'fa-sort-down'
      }
    })

    function doSort(): void {
      const sortSpec: SortSpec = {
        key: props.sortKey,
        // ソートNONEかDESCの状態でクリックされたらASC、それ以外はDESC
        asc: [SORT_STATE_NONE, SORT_STATE_DESC].includes(currentSortState.value),
      }
      // スタッフ名のソートの場合は比較関数を切り替え
      if (props.compareFuncType === 'staffName') {
        sortSpec.compareFunc = compareFuncStaff
      }
      onSort!([sortSpec])
    }

    return {
      sortIconClass,
      doSort,
    }
  }
})
