<template>
  <div class="wrapper">

    <sidebar type="sidebar" :sidebar-store="sidebarStore">
      <user-menu></user-menu>
    </sidebar>

    <div class="main-panel">
      <top-navbar :sidebar-store="sidebarStore"></top-navbar>

      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
import TopNavbar from './TopNavbar'
import ContentFooter from './ContentFooter'
import DashboardContent from './Content'
import UserMenu from 'src/components/UIComponents/Sidebar/UserMenu'
import { createSidebarStore } from 'src/components/UIComponents/Sidebar/createSidebarStore.ts'
import sidebarLinks from './sidebarLinks.js'

import 'src/assets/sass/sample-demo.scss'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.sidebarStore.showSidebar) {
        this.sidebarStore.displaySidebar(false)
      }
    }
  },
  data() {
    const sidebarStore = createSidebarStore()
    sidebarStore.sidebarLinks = sidebarLinks
    return {
      sidebarStore: sidebarStore,
    }
  },
}
</script>
