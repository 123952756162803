









import { defineComponent, SetupContext, PropType } from '@vue/composition-api'
import Encoding from 'encoding-japanese'
import { CsvLine } from 'src/util/type_util'

interface PrepareCsvDataFunc {
  (): void
}

export default defineComponent({
  name: 'csv-download',
  props: {
    csvData: {
      type: Array as PropType<CsvLine[]>,
      required: true,
    },
    csvFileName: {
      type: String,
      required: true,
    },
    prepareCsvData: {
      type: Function as PropType<PrepareCsvDataFunc>,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'CSV出力'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    enclosedByDoubleQuotation: {
      type: Boolean,
      default: false
    },
  },
  setup(props, context: SetupContext) {
    async function downloadCsv() {
      // not using emit to handle promise!
      await props.prepareCsvData()
      if (props.csvData.length === 0) { return }
      let csvContents: string = ''
      props.csvData.forEach(r => {
        let line: string = ''
        r.forEach(c => {
          line += props.enclosedByDoubleQuotation ? `"${c}",` : `${c},`
        })
        // remove last ',' before adding line change code
        csvContents += line.slice(0, -1) + '\r\n'
      })
      // remove last line change
      csvContents = csvContents.trim()
      // https://note.com/nowbuilding/n/n05702862f575
      const convertedToUnicode = Encoding.stringToCode(csvContents)
      const convertedToSjis = Encoding.convert(convertedToUnicode, 'sjis', 'unicode')
      const convertedToBinary = new Uint8Array(convertedToSjis)
      const blob = new Blob([convertedToBinary], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = props.csvFileName
      link.click()
    }
    return {
      downloadCsv,
    }
  }
})
