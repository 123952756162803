// throttleとかdebounceが実装してあってもいいよね的な

export default {
  data() {
    return {
      timingControlMixin_: {}
    }
  },
  methods: {
    // 戻り値は返さなくなる点に注意
    makeAsyncFuncGated(func, tag = 'isLoading') {
      return async(...args) => {
        if (this.timingControlMixin_[tag]) { return }
        this.timingControlMixin_[tag] = true
        try {
          await func(...args)
        } finally {
          this.timingControlMixin_[tag] = false
        }
      }
    },
  }
}
