import axios from 'axios'
import * as utils from 'src/apis/apiUtils'

export default {
  create({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces_budget_groups`), data)
  },
  delete({ workplaceId, itemId }) {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces_budget_groups/${itemId}`))
  },
  importCsv({ workplaceId, data, headers }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/users_workplaces_budget_groups/import_csv`),
      data,
      { headers }
    )
  },
}
