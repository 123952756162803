<template src="./template.html"></template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as C from 'src/consts'
import notificationMixin from 'src/mixins/notificationMixin'
import masterMixin from 'src/mixins/masterMixin'
import usersWorkplaceApi from 'src/apis/workplace_masters/users_workplace'
import usersWorkplacesBudgetGroupApi from 'src/apis/workplace_masters/users_workplaces_budget_group'
import budgetGroupApi from 'src/apis/workplace_masters/budget_group'
import timingControlMixin from 'src/mixins/timingControlMixin'
import windowMixin from 'src/mixins/windowMixin'
import BudgetGroupCard from './BudgetGroupCard'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureWorkplaceRoleGteOwner } from 'src/hooks/appRoleHook'

const displayPageName = '共有設定'

export default {
  components: { BudgetGroupCard },
  data() {
    return {
      displayPageName: displayPageName,
      searchParams: {
        displayListDisabledItem: false,
        user: {},
      },

      list: [],
      companyCandidates: [],
      saveCandidate: {},
      roleCandidates: [],
      inviteRoleCandidates: [],
      roleMap: {},
      userWorkplaceStatusMap: {},
      budgetGroups: [],

      inviteModalInfo: {},
      deleteModalInfo: {},
      showSaveModal: false,
      showInviteModal: false,
      showCreateModal: false,
      showDeleteModal: false,

      validations: this.getValidationMap(),

      userRoleConst: {
        wOwner: C.USER_WORKPLACE_ROLE_W_OWNER,
        wLeader: C.USER_WORKPLACE_ROLE_W_LEADER,
        wMember: C.USER_WORKPLACE_ROLE_W_MEMBER,
      },
      fixTable: true,
      csvImportResult: {
        successCount: 0,
        failCount: 0,
        failReasons: [],
      },
      isLoadCsv: false,
      showCsvImportResultModal: false,
      isSuperAdmin: false,
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters('user', {
      userId: 'id',
    }),
    ...mapGetters('workplace', {
      useCreateAndUpdateInfo: 'useCreateAndUpdateInfo',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    hasList() {
      return this.list.length > 0
    },
    hasError() {
      return this.errors.any()
    },
    hasUpdateError() {
      if (!('role' in this.saveCandidate)) { return false }
      if (this.hasOtherOwner) { return false }
      if (this.saveCandidate.user_id !== this.userId) { return false }
      return this.saveCandidate.role !== this.userRoleConst.wOwner
    },
    hasSearchParamsUser() {
      return Object.keys(this.searchParams.user).length > 0
    },
    hasOtherOwner() {
      // whether other owner besides the logged in user exists or not
      return this.list.some(e => {
        return (e.role === this.userRoleConst.wOwner) && (e.user_id !== this.userId)
      })
    },
    rowWidth() {
      return 1020 + this.useCreateAndUpdateInfo * 740
    }
  },
  mixins: [notificationMixin, masterMixin, timingControlMixin, windowMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = {
        required: true,
        max: 255,
      }
      return {
        // vee-validateのemailはマルチバイト文字OKなので、ここでは正規表現でマルチバイト文字を禁止する
        // UTF-8における1Byte文字(\u0000-\u007f)でない文字を含まないことを確認する
        email: { required: true, email: true, regex: '^[\u0000-\u007f]+$' },
        email_confirm: { required: true, confirmed: 'inviteEmail' },
        role: { required: true },
        family_name: ruleStr,
        first_name: ruleStr,
        job_title: ruleStr,
        password: {
          required: true,
          min: 8,
        },
        password_confirmation: {
          required: true,
          confirmed: 'password',
        },
        budget_group_ids: { required: true },
      }
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getList() {
      const { data } = await usersWorkplaceApi.index({ workplaceId: this.workplaceId })
      data.forEach(e => {
        // alias
        e.fullName = `${e.user.family_name} ${e.user.first_name}`
        e.email = e.user.email
      })

      // sort
      const sortValMap = {
        [this.userRoleConst.wOwner]: 1,
        [this.userRoleConst.wLeader]: 2,
        [this.userRoleConst.wMember]: 3,
      }
      data.sort((a, b) => {
        const v1 = sortValMap[a.role] || 1000
        const v2 = sortValMap[b.role] || 1000
        return v1 < v2 ? -1 : 1
      })

      this.list = data
      // default selected
      if (Object.keys(this.searchParams.user).length === 0 && this.hasList) {
        this.searchParams.user = this.list[0]
      }
    },
    async getCompanies() {
      const { data: usersWorkplacesAndCompanies } = await usersWorkplaceApi.getCompanies({ workplaceId: this.workplaceId })
      this.companyCandidates = usersWorkplacesAndCompanies.map(e => e.company)
    },
    getApiParams() {
      let apiParams = { is_enabled: true }
      return apiParams
    },
    async getBudgetGroups() {
      const { data } = await budgetGroupApi.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      })
      this.budgetGroups = data
    },
    openSaveModal(item) {
      this.saveCandidate = JSON.parse(JSON.stringify(item))
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      // this.clearErrors()
      this.showSaveModal = false
    },
    async saveItem() {
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await usersWorkplaceApi.update(reqObj)
        this.closeSaveModal()
        this.notifySuccess1(`役割を変更しました`)
        this.reloadOpener()
        // If owner changes themself to leader or member then,
        // kick-out to setting screen. (only owner can see this screen)
        if ((reqObj.data.user_id === this.userId) && reqObj.data.role !== this.userRoleConst.wOwner) {
          // store must be updated
          const roleMap = this.$store.getters['user/workplaceRoleMap']
          roleMap[this.workplaceId]['role'] = reqObj.data.role
          await this.$router.push({ name: 'SettingsSelect' })
        } else {
          this.getList()
        }
      } catch (err) {
        const errId = 'ERR00001'
        const msg = `役割の変更に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    openInviteModal(item) {
      this.errors.clear()
      this.inviteModalInfo = {
        role: this.inviteRoleCandidates[0].key,
        spKey: localStorage.getItem('sp'),
      }
      this.showInviteModal = true
    },
    closeInviteModal() {
      this.clearErrors()
      this.showInviteModal = false
    },
    isDuplicateEmail(email) {
      for (const item of this.list) {
        if (item.email === email) {
          return true
        }
      }
      return false
    },
    async inviteToWorkplace() {
      const isValid = await this.$validator.validateAll('formInvite')
      if (!isValid) { return }

      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.inviteModalInfo,
        }
        await usersWorkplaceApi.create(reqObj)
        this.getList()
        this.closeInviteModal()
        this.notifySuccess1(`ユーザーをセンターに招待しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 404) {
          const msg = `そのEメールで登録済のユーザーが見つかりませんでした。`
          this.notifyError1(msg, { timeout: 5 * 1000 })
          this.closeInviteModal()
          this.openCreateModal()
        } else if (errStatus === 400 && errRes.reason === 'dup_email') {
          const msg = `そのEメールはこのセンターに招待済みです。` +
            `招待しなおす場合は、一度削除してから、再度招待をしてください`
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00002'
          const msg = `センターへの招待に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    openCreateModal(item) {
      this.inviteModalInfo.companyName = this.companyCandidates[0].name
      this.inviteModalInfo.company_id = this.companyCandidates[0].id
      this.showCreateModal = true
    },
    closeCreateModal() {
      this.clearErrors()
      this.showCreateModal = false
    },
    async createUserAndInviteToWorkplace() {
      const isValid = await this.$validator.validateAll('formCreate')
      if (!isValid) { return }
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.inviteModalInfo,
        }
        await usersWorkplaceApi.createWithUser(reqObj)
        this.getList()
        this.closeCreateModal()
        this.notifySuccess1(`ユーザーを登録し、センターに招待しました`)
        this.reloadOpener()
      } catch (err) {
        const errId = 'ERR00002'
        const msg = `ユーザー登録とセンターへの招待に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    openDeleteModal(item) {
      this.deleteModalInfo = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    async deleteShareSetting() {
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteModalInfo.id,
        }
        await usersWorkplaceApi.delete(reqObj)
        this.getList()
        this.closeDeleteModal()
        this.notifySuccess1(`共有設定を削除しました`)
        this.reloadOpener()
      } catch (err) {
        const errId = 'ERR00003'
        const msg = `共有設定の削除に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem)
      this.inviteToWorkplace = this.makeAsyncFuncGated(this.inviteToWorkplace)
      this.deleteShareSetting = this.makeAsyncFuncGated(this.deleteShareSetting)
    },
    selectItem(item) {
      this.searchParams.user = item
    },
    isSelectedItem(item) {
      if (Object.keys(this.searchParams.user).length === 0) {
        return false
      }
      if (this.searchParams.user.user_id !== item.user_id) {
        return false
      }
      return true
    },
    onRoleChange() {
      if (!this.hasOtherOwner) {
        if (this.saveCandidate.role !== this.userRoleConst.wOwner) {
          this.hasUpdateError = true
        }
      }
    },
    async onCompanyChange(company) {
      this.inviteModalInfo.company_id = company.id
    },
    openFileDialog() {
      this.$refs.csvInput.$el.children[0].click()
    },
    async onCsvUpload(e) {
      // ファイルが選択されていない場合は何もしない
      const selectedFileList = e.target.files
      if (!selectedFileList || !selectedFileList[0]) { return }

      this.isLoadCsv = true
      try {
        const data = new FormData()
        data.append('csv', selectedFileList[0])
        data.append('spKey', localStorage.getItem('sp'))
        const reqObj = {
          workplaceId: this.workplaceId,
          data: data,
          headers: { 'content-type': 'multipart/form-data' },
        }
        const result = (await usersWorkplacesBudgetGroupApi.importCsv(reqObj)).data
        this.getList()
        this.openCsvImportResultModal(result)
      } catch (err) {
        this.isLoadCsv = false
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        let msg = ''
        switch (true) {
          case errStatus === 400 && errRes.reason === 'invalid_data':
            msg = 'ファイル形式、またはCSVフォーマットが間違っています。'
            break
          case errStatus === 400 && errRes.reason === 'import_limit_flag':
            msg = '取込み行数は最大50行です。'
            break
          case errStatus === 400 && errRes.reason === 'invalid_contents':
            msg = 'ファイル内容が不正です。'
            break
        }
        if (msg) this.notifyError1(msg, { timeout: 5 * 1000 })
      }
    },
    openCsvImportResultModal(result) {
      this.isLoadCsv = false
      this.csvImportResult.successCount = result['success_count']
      this.csvImportResult.failCount = result['fail_count']
      this.csvImportResult.failReasons = result['fail_reasons']
      this.showCsvImportResultModal = true
    },
    closeCsvImportResultModal() {
      this.showCsvImportResultModal = false
    },
    isFailedImport() {
      return this.csvImportResult.failReasons.length > 0
    },
    onBudgetGroupChange(budgetGroupIds) {
      this.inviteModalInfo.budget_group_ids = budgetGroupIds
    }
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, displayPageName)
    await this.ensureUserAndMasters()
    // 招待用のrole候補
    this.inviteRoleCandidates = window.master.lovs.user_role.vals_inuse
    // 編集用のrole候補
    this.roleCandidates = window.master.lovs.user_role.vals_inuse
    this.roleMap = window.master.lovs.user_role.map_inuse
    this.userWorkplaceStatusMap = window.master.lovs.user_workplace_status.map_inuse
    if (this.$store.getters['user/hasRoleSuperAdmin']) {
      this.isSuperAdmin = true
    }
    await ensureWorkplaceRoleGteOwner(this, this.workplaceId)
    await this.getList()
    await this.getCompanies()
    await this.getBudgetGroups()
    this.inviteModalInfo = {}
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
