import DashboardLayout from 'src/components/Samples/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
// import NotFound from 'src/components/GeneralViews/NotFoundPage.vue'

// Pages
import User from 'src/components/Samples/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Samples/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Samples/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Samples/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Samples/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Samples/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Samples/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Samples/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Samples/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Samples/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Samples/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Samples/Dashboard/Views/Components/Typography.vue'

// Calendar
import Calendar from 'src/components/Samples/Dashboard/Views/Calendar/CalendarRoute.vue'

import GoogleMaps from 'src/components/Samples/Dashboard/Views/Maps/GoogleMaps.vue'

// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Samples/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Samples/Dashboard/Views/Dashboard/Widgets.vue')

// Forms pages
const RegularForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Samples/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Samples/Dashboard/Views/Forms/ExtendedForms.vue')
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Samples/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Samples/Dashboard/Views/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Samples/Dashboard/Views/Tables/RegularTables.vue')
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Samples/Dashboard/Views/Tables/ExtendedTables.vue')
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Samples/Dashboard/Views/Tables/PaginatedTables.vue')
// Maps pages
// const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Samples/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Samples/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Samples/Dashboard/Views/Maps/VectorMapsPage.vue')

// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Samples/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: 'components',
  component: DashboardLayout,
  redirect: 'components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Sample Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Sample Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Sample Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sample Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Sample Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Sample Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Sample Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: 'forms',
  component: DashboardLayout,
  redirect: 'forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Sample Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Sample Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Sample Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Sample Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: 'table-list',
  component: DashboardLayout,
  redirect: 'table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Sample Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Sample Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Sample Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: 'maps',
  component: DashboardLayout,
  redirect: 'maps/google',
  children: [
    {
      path: 'google',
      name: 'Sample Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Sample Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Sample Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: 'pages',
  component: DashboardLayout,
  redirect: 'pages/user',
  children: [
    {
      path: 'user',
      name: 'Sample User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Sample Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: 'login',
  name: 'Sample Login',
  component: Login
}

let registerPage = {
  path: 'register',
  name: 'Sample Register',
  component: Register
}

let lockPage = {
  path: 'lock',
  name: 'Sample Lock',
  component: Lock
}

const routes = [
  {
    path: '',
    name: 'SampleAdminOverview',
    component: DashboardLayout,
    redirect: 'admin/overview',
    children: [
      {
        path: 'calendar',
        name: 'Sample Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Sample Charts',
        component: Charts
      }
    ]
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: 'admin',
    component: DashboardLayout,
    redirect: 'admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Sample Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Sample Widgets',
        component: Widgets
      }
    ]
  },
]

export default routes
