
import Vue from 'vue'
import { defineComponent, SetupContext } from '@vue/composition-api'
import { setPageName } from 'src/hooks/displayPageNameHook'

export default defineComponent({
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    setPageName(root, '共通設定')

    return {}
  }
})
