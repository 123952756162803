import axios from 'axios'
import * as utils from 'src/apis/apiUtils'

export default {
  index({ workplaceId, params }) {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels`), {
      params: params || {}
    })
  },
  create({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels`), data)
  },
  update({ workplaceId, data }) {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/${data.id}`), data)
  },
  delete({ workplaceId, itemId }) {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/${itemId}`))
  },
  bulkUpdateDispOrder({ workplaceId, items }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/bulk_update_disp_order`),
      { items: items }
    )
  },
}
